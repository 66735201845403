/**
 * 自动生成的代码，请勿修改
 */
import { doRequest } from './utils';
import type { ResponseType } from './utils';
import type {
  ListAttrTagRequest,
  ResponseListAttrTagResponse,
  QueryAttrInfoRequest,
  ResponseQueryAttrInfoResponse,
  QueryAttrDetailRequest,
  ResponseQueryAttrDetailResponse,
  ListAttrProductRequest,
  ResponseListAttrProductResponse,
  QueryVisitUrlRequest,
  ResponseQueryVisitUrlResponse,
  ListSupportSupplierRequest,
  ResponseListSupportSupplierResponse,
  BaseRequestDTO,
  ResponseSearchFilterConfigDto,
  ResponseExperienceSearchFilterConfigDTO,
  GetAttrProductsRequest,
  ResponseGetAttrProductsResponse,
} from '../types';

const baseUrl = '';

class AttrControllerService {
  /**
   * @summary 查询景区的标签*
   * @fullUrl /attr/tag/list
   */
  public attrTagListPost(
    body: ListAttrTagRequest,
  ): ResponseType<ResponseListAttrTagResponse> {
    const requestUrl = baseUrl + '/attr/tag/list';
    const res = doRequest<ResponseListAttrTagResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询景区基本信息*
   * @fullUrl /attr/info
   */
  public attrInfoPost(
    body: QueryAttrInfoRequest,
  ): ResponseType<ResponseQueryAttrInfoResponse> {
    const requestUrl = baseUrl + '/attr/info';
    const res = doRequest<ResponseQueryAttrInfoResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询景区详细信息*
   * @fullUrl /attr/detail
   */
  public attrDetailPost(
    body: QueryAttrDetailRequest,
  ): ResponseType<ResponseQueryAttrDetailResponse> {
    const requestUrl = baseUrl + '/attr/detail';
    const res = doRequest<ResponseQueryAttrDetailResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询景区产品信息*
   * @fullUrl /attr/list/product
   */
  public attrListProductPost(
    body: ListAttrProductRequest,
  ): ResponseType<ResponseListAttrProductResponse> {
    const requestUrl = baseUrl + '/attr/list/product';
    const res = doRequest<ResponseListAttrProductResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询景区产品外跳的链接*
   * @description 底层逻辑是创建snapshot*
   * @fullUrl /attr/query/visit/out/url
   */
  public attrQueryVisitOutUrlPost(
    body: QueryVisitUrlRequest,
  ): ResponseType<ResponseQueryVisitUrlResponse> {
    const requestUrl = baseUrl + '/attr/query/visit/out/url';
    const res = doRequest<ResponseQueryVisitUrlResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询支持的供应商*
   * @fullUrl /attr/list/support/supplier
   */
  public attrListSupportSupplierPost(
    body: ListSupportSupplierRequest,
  ): ResponseType<ResponseListSupportSupplierResponse> {
    const requestUrl = baseUrl + '/attr/list/support/supplier';
    const res = doRequest<ResponseListSupportSupplierResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询搜索过滤配置
   * @fullUrl /attr/listSearchFilterConfig
   */
  public attrListSearchFilterConfigPost(
    body: BaseRequestDTO,
  ): ResponseType<ResponseSearchFilterConfigDto> {
    const requestUrl = baseUrl + '/attr/listSearchFilterConfig';
    const res = doRequest<ResponseSearchFilterConfigDto>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 体验类榜单搜索过滤配置
   * @fullUrl /attr/listExperienceSearchFilterConfig
   */
  public attrListExperienceSearchFilterConfigPost(
    body: BaseRequestDTO,
  ): ResponseType<ResponseExperienceSearchFilterConfigDTO> {
    const requestUrl = baseUrl + '/attr/listExperienceSearchFilterConfig';
    const res = doRequest<ResponseExperienceSearchFilterConfigDTO>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
  /**
   * @summary 查询分组景区产品信息
   * @fullUrl /attr/list/groupedProduct
   */
  public attrListGroupedProductPost(
    body: GetAttrProductsRequest,
  ): ResponseType<ResponseGetAttrProductsResponse> {
    const requestUrl = baseUrl + '/attr/list/groupedProduct';
    const res = doRequest<ResponseGetAttrProductsResponse>({
      method: 'post',
      url: requestUrl,
      body,
    });
    return res;
  }
}

export const attrController = new AttrControllerService();
